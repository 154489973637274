function getDomain() {
  const hostname = window.location.hostname;
  return '.' + hostname.split('.').slice(1).join('.');
}

function getCookie(cName: string) {
  const name = cName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';');
  for (let cookie of cookies) {
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
}

function setCookie(cName: string, cValue: string, expireDays = 365) {
  const today = new Date(Date.now());
  today.setTime(today.getTime() + expireDays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + today.toUTCString();
  const cDomain = `; domain=${getDomain()}`;
  const cPolicy = '; SameSite=Lax';
  const newCookie = cName + '=' + encodeURIComponent(cValue) + '; ' + expires + '; path=/' + cDomain + cPolicy;
  document.cookie = newCookie;
  return newCookie;
}

function deleteCookie(cName: string) {
  const expires = 'expires=Thu, 01 Jan 1970 00:00:00 UTC';
  const cDomain = `; domain=${getDomain()}`;
  const cPolicy = '; SameSite=Lax';
  const newCookie = cName + '=; ' + expires + '; path=/' + cDomain + cPolicy;
  document.cookie = newCookie;
}

const COOKIE_AUTH_SESSION = 'nn_auth_session';
const COOKIE_AUTH_USER = 'nn_auth_user';
const COOKIE_AUTH_LOGOUT = 'nn_auth_logout';

const CookieManager = {
  COOKIE_AUTH_LOGOUT,
  COOKIE_AUTH_SESSION,
  COOKIE_AUTH_USER,
  deleteCookie,
  getCookie,
  setCookie,
};

export default CookieManager;
