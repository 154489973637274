import { LanguageSelectors } from '@web_opxp/features';
import { all, call, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { ApiService, HttpError, localStorage } from 'web_core_library';
import { ApiResponse } from '../core/apiResponse';
import { HttpStatusCode } from '../core/constants';
import CookieManager from '../services/cookieManager';
import { getRefreshTokenHandler } from '../services/historyService';
import urlManager, { createPasswordBackUrl } from '../services/urlManager';
import { SocialActionTypes, SocialActions, socialSagaWatcher } from '../social';
import * as ActionTypes from './actionTypes';
import * as Actions from './actions';
import * as Selectors from './selectors';
import Service from './service';
import * as Types from './types';
import {
  getAccessToken,
  getRandomPassword,
  isUserSocial,
  resetCurrentUserState,
  setCurrentUser,
  userPremium,
  userVerified,
} from './utils';

export function* restoreAuthSaga() {
  // start restoring the session
  let session = null;
  Service.init(ApiService);
  // first step - check if user has came with access token
  let accessToken = urlManager.getAccessToken();

  if (accessToken) {
    urlManager.removeAccessToken();
    // if access token is given in url - override previously logged in user
    yield call(handleUserReset);
    // if access token is given in url its not a social login
    session = yield call(validateToken, accessToken, false);
  }
  if (!session) {
    // check if user is logged in and access token saved
    accessToken = yield call(getAccessToken);
    if (accessToken) {
      // check if user was logged in with social
      const isSocial: boolean = yield call(isUserSocial);
      session = yield call(validateToken, accessToken, isSocial);
    }
  }

  // user data is not present in browser
  // third step try to restore social login
  if (!session) {
    // check social login status
    yield put(SocialActions.restoreSocialAuthRequestAction());
    const socialResult: SocialActionTypes.ISocialRestoreResult = yield take([
      SocialActionTypes.SOCIAL_LOGIN_FAIL,
      SocialActionTypes.SOCIAL_RESTORE_SUCCESS,
      SocialActionTypes.SOCIAL_RESTORE_FAIL,
    ]);
    if (socialResult.type === SocialActionTypes.SOCIAL_RESTORE_SUCCESS) {
      session = yield call(validateToken, socialResult.accessToken, true);
    }
  }
  if (!session) {
    yield put(Actions.restoreAuthFailAction());
    return;
  }
  CookieManager.setCookie(CookieManager.COOKIE_AUTH_SESSION, session);
  yield put(Actions.restoreAuthSuccessAction());
}

export function* validateToken(accessToken: string, social = false) {
  try {
    const validationResult = yield call(Service.validate, accessToken);
    const { sessionToken, userId } = validationResult.data.result;
    yield call(ApiService.setUserData, userId, sessionToken, getRefreshTokenHandler(false));
    const rolesResult = yield call(Service.getRoles, userId);
    const user = rolesResult.data.user;
    const email = user.mail;
    const verified = userVerified(user.roles);
    const premium = userPremium(user.roles);
    const userSession: Types.User = {
      userId,
      verified,
      email,
      social,
      accessToken,
      premium,
    };
    setCurrentUser(userId, userSession);
    yield put(Actions.saveUserAction({ ...userSession, session: sessionToken }));
    return sessionToken;
  } catch (error) {
    yield put(Actions.sessionExpired(new ApiResponse(error as HttpError)));
    return '';
  }
}

export function* loginUser(email: string, password: string, couponToken?: string) {
  try {
    const response = yield call(Service.login, email, password, couponToken);
    const { accessToken, userId } = response.data.result;
    const userSession: Partial<Types.User> = {
      accessToken,
      userId,
    };
    setCurrentUser(userId, userSession);
    CookieManager.setCookie(CookieManager.COOKIE_AUTH_USER, email);
    return new ApiResponse(response);
  } catch (error) {
    return new ApiResponse(error as HttpError);
  }
}

export function* handleLoginSaga({ email, password }: ActionTypes.ILoginUserAction) {
  const couponToken: string | undefined = yield select(Selectors.getCouponToken);
  const result: ApiResponse = yield call(loginUser, email, password, couponToken);
  if (result.isError()) {
    let message = 'common:Errors.System';
    let couponInvalid = false;
    switch (result.status) {
      case HttpStatusCode.Unauthorized:
        message = 'common.auth:Login.Errors.Auth';
        break;
      case HttpStatusCode.NotFound:
        message = 'opxp.prereg:Coupon.Error.NotFound';
        couponInvalid = true;
        break;
      case HttpStatusCode.InvalidData: {
        couponInvalid = true;
        const internalMessage = result.data ? result.data.message : '';
        switch (internalMessage) {
          case 'Coupon is already reserved.':
            message = 'opxp.prereg:Coupon.Error.Reserved';
            break;
          case 'Coupon could not be reserved.':
            message = 'opxp.prereg:Coupon.Error.Invalid';
            break;
          case 'User is already premium.':
            message = 'opxp.prereg:Coupon.Error.Premium';
            break;
          case 'Coupon reserved time is expired':
            message = 'opxp.prereg:Coupon.Error.Expired';
            couponInvalid = true;
            break;
          default:
            message = internalMessage;
            break;
        }
        break;
      }
    }
    yield put(Actions.loginFailedAction(message, couponInvalid));
    return;
  }
  yield put(Actions.loginSuccessAction());
}

export function* handleSignup({ password, email, attribution, useBackUrl }: ActionTypes.ISignupAction) {
  try {
    const couponToken = yield select(Selectors.getCouponToken);
    const lang = yield select(LanguageSelectors.getLanguage);
    const backUrl = useBackUrl ? urlManager.getBackUrl() : createPasswordBackUrl();
    const result = yield call(Service.signup, email, password, lang, backUrl, attribution, couponToken);
    yield put(Actions.signupSuccessAction(new ApiResponse(result)));
  } catch (error) {
    yield put(Actions.signupFailAction(new ApiResponse(error as HttpError)));
  }
}

export function* handleEmailUpdate({ user }: ActionTypes.IUpdateUserStateAction) {
  yield put(Actions.saveUserAction(user));
  const userId = yield select(Selectors.getUserId);
  setCurrentUser(userId, user);
}

export function* handleTokenValidate({ token, social }: ActionTypes.IValidateTokenAction) {
  const sessionToken = yield call(validateToken, token, social);
  if (!sessionToken) {
    yield put(Actions.validateTokenFailAction());
    return;
  }
  const userId = yield select(Selectors.getUserId);
  yield put(Actions.validateTokenSuccessAction(userId, sessionToken));
}

export function* handleUserSubscribe({ email }: ActionTypes.ISubscribeUserAction) {
  localStorage.remove('session');
  const password = getRandomPassword();
  yield put(Actions.signupAction(email, password));
  const result = yield take([ActionTypes.AUTH_SIGNUP_FAIL, ActionTypes.AUTH_SIGNUP_SUCCESS]);
  if (result.type === ActionTypes.AUTH_SIGNUP_FAIL) {
    yield put(Actions.subscribeUserFailAction());
    return;
  }
  const loginResult = yield call(loginUser, email, password);
  if (loginResult.isError()) {
    yield put(Actions.subscribeUserFailAction());
    return;
  }
  yield put(Actions.subscribeUserSuccessAction());
}

export function* handleUserReset() {
  yield call(resetCurrentUserState);
}

export default function* featureWatcher() {
  yield all([
    takeEvery(ActionTypes.AUTH_RESTORE, restoreAuthSaga),
    takeEvery(ActionTypes.AUTH_LOGIN, handleLoginSaga),
    takeEvery(ActionTypes.AUTH_SIGNUP, handleSignup),
    takeEvery(ActionTypes.AUTH_UPDATE_STATE, handleEmailUpdate),
    takeEvery(ActionTypes.AUTH_VALIDATE_TOKEN, handleTokenValidate),
    takeEvery(ActionTypes.AUTH_SUBSCRIBE_USER, handleUserSubscribe),
    takeEvery(ActionTypes.AUTH_RESET, handleUserReset),
    fork(socialSagaWatcher),
  ]);
}
